import React from 'react';
import Layout from '../components/Layout';
import GalleryComponent from 'react-grid-gallery';
import styled from 'styled-components';
// images
import Image1 from '../img/gallery/Image1.jpg';
import Image2 from '../img/gallery/Image2.jpg';
import Image3 from '../img/gallery/Image3.jpg';
import Image4 from '../img/gallery/Image4.jpg';
import Image5 from '../img/gallery/Image5.jpg';

const DivContainer = styled.div`
  max-width: 800px;
  @media screen and (max-width: 500px) {
    max-width: 300px;
  }
  @media screen and (max-width: 800px) {
    max-width: 500px;
  }
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
`;

const images = [
  {
    src: Image1,
    thumbnail: Image1,
    thumbnailWidth: 100,
    thumbnailheight: 50,
  },
  {
    src: Image2,
    thumbnail: Image2,
    thumbnailWidth: 100,
    thumbnailheight: 50,
  },
  {
    src: Image3,
    thumbnail: Image3,
    thumbnailWidth: 100,
    thumbnailheight: 50,
  },
  {
    src: Image4,
    thumbnail: Image4,
    thumbnailWidth: 100,
    thumbnailheight: 50,
  },
  {
    src: Image5,
    thumbnail: Image5,
    thumbnailWidth: 100,
    thumbnailheight: 50,
  }
];

const Gallery = () => {
  return (
    <Layout>
      <div
        className="content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '50px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <DivContainer>
          <h1 className="has-text-centered">Gallery</h1>

          <GalleryComponent enableImageSelection={false} images={images} />
        </DivContainer>
      </div>
    </Layout>
  );
};

export default Gallery;
